import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId[// RINKEBY = 4,
    "GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["opBNB"] = 204] = "opBNB";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["ARB_TESTNET"] = 421613] = "ARB_TESTNET";
    ChainId[ChainId["ARB"] = 42161] = "ARB";
    ChainId[ChainId["ZKSYNC"] = 324] = "ZKSYNC";
    ChainId[ChainId["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.GOERLI, "0xa373C0460cD7c1A355E07a004c8f8651aDE8a3d3"), _define_property(_obj, ChainId.BSC, "0x84c189d8B52Be28AD4C4c9427aBd304268b2FCBA"), _define_property(_obj, ChainId.BSC_TESTNET, "0xe177E3249cb88565eaF8Ff30A03798AB05f5B0aD"), _define_property(_obj, ChainId.ARB_TESTNET, "0x6941CD0d6EF3E4c1294b85fe6EF275AA6C4691fb"), _define_property(_obj, ChainId.ZKSYNC, ""), _define_property(_obj, ChainId.opBNB, "0x45500361eAEe1030a4e76f59396F10f1C5374Fb6"), _obj);
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.GOERLI, "0xbd6e9c8068984bfca91aed95f2f98658a71e81a7e6fdc3ee14a32e18282b6fd6"), _define_property(_obj1, ChainId.BSC, "0x03835b706f9d113189195657a879689bd6bd25bcb39e55b463074e777ab96aa9"), _define_property(_obj1, ChainId.BSC_TESTNET, "0x36c213bfc4903e805f10edf4b52cd1beea3ec333f78e9887fe73f3d05a06cf3a"), _define_property(_obj1, ChainId.ARB_TESTNET, "0xddb43bdccbba1a5f8ed99fdaccbafbcb4292989533b27066454dccc7f50ff467"), _define_property(_obj1, ChainId.opBNB, "0x8b247e9de929d1981fa895749984a9c325040c7f0db1f55df5d636cfb9e7de81"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9970);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
